<!--Pagina de Registro-->
<template>
  <v-container
    fill-height
    fluid
    class="black d-flex align-stretch justify-center"
  >
    <v-row class="" justify="center" no-gutters>
      <v-col cols="12" md="11" class="py-0 px-0">
        <!-- <menus/>Contenido del menu -->
        <v-toolbar elevation="0" class="menugrande black">
          <v-img
            :src="require('../assets/beckslogo3.png')"
            max-width="150px"
            class="ml-15"
          >
          </v-img>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row justify="center" class="my-0 px-0 py-n10">
          <v-col cols="12" md="6" class="px-0 py-0"
            ><!-- Contenido de la izquierda-->
            <v-img
              :aspect-ratio="1"
              width="100%"
              min-width="200"
              class="d-none d-md-flex align-center justify-start"
              :src="require('../assets/becksjustificacionweb.jpg')"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="5" class="text-center">
            <!--CONTENIDO DE LA DERECHA-->
            <div class="text-center">
              <h1 class="white--text textoR">PARTICIPA</h1>
              <v-spacer />
            </div>
            <!--INICIO DE FORMULARIO-->
            <v-card color="black" max-width="400" class="mx-auto text-left">
              <v-form class="mt-10" ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="nombre"
                  :rules="[(v) => !!v || 'INGRESA UN NOMBRE', (v) => /^[a-z\sñÑáéíóúÁÉÍÓÚ]+$/i.test(v) || 'INGRESA UN NOMBRE']"
                  height="45px"
                  label="NOMBRE/s"
                  pattern="[a-zA-Z]"
                  required
                  outlined
                  class="my-n1"
                  rounded
                  dark
                  @keyup="nombre = sinNumeros(nombre)"
                >
                  <template v-slot:label>
                    <div class="grey--text mt-n1" style="font-size: 10pt">
                      NOMBRE/s *
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="apellido"
                  :rules="[(v) => !!v || 'INGRESA UN APELLIDO']"
                  height="45px"
                  label="APELLIDO/s"
                  required
                  outlined
                  class="my-n1"
                  rounded
                  @keyup="apellido = sinNumeros(apellido)"
                  dark
                >
                  <template v-slot:label>
                    <div class="grey--text mt-n1" style="font-size: 10pt">
                      APELLIDO/s *
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="correoVerificado"
                  v-model="email"
                  height="45px"
                  :rules="emailRules"
                  placeholder="CORREO ELECTRÓNICO"
                  required
                  class="my-n1"
                  outlined
                  rounded
                  dark
                  @change="verificarMail()"
                >
                  <template v-slot:label>
                    <div class="grey--text mt-n1" style="font-size: 10pt">
                      EMAIL 
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="!correoVerificado"
                  v-model="email"
                  height="45px"
                  :rules="correoTemporal?[() => ('NO SE PERMITEN CORREOS TEMPORALES')]:[() => ('ESTE CORREO YA HA SIDO REGISTRADO')]"
                  placeholder="CORREO ELECTRÓNICO"
                  required
                  class="my-n1"
                  outlined
                  rounded
                  dark
                  @change="verificarMail()"
                >
                  <template v-slot:label>
                    <div class="grey--text mt-n1" style="font-size: 10pt">
                      EMAIL 
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="telefono"
                  v-if="telefonoVerificado"
                  height="45px"
                  maxlength="10"
                  minlength="7"
                  :rules="[(v) => !!v || 'INGRESA UN NUMERO DE TELÉFONO',(v) => v.length == 10 || 'INGRESA UN NUMERO DE TELÉFONO']"
                  required
                  counter="10"
                  placeholder="NÚMERO DE TELÉFONO *"
                  type="number"
                  class="my-1"
                  outlined
                  rounded
                  dark
                  @change="verificarTel()"
                  @keyup="maxlengthTel()"
                >
                </v-text-field>
                <v-text-field
                  v-if="!telefonoVerificado"
                  v-model="telefono"
                  height="45px"
                  maxlength="10"
                  minlength="7"
                  :rules="[(v) => !!v || 'INGRESA UN NUMERO DE TELÉFONO',(v) => v.length == 10 || 'INGRESA UN NUMERO DE TELÉFONO',() => ('ESTE TELÉFONO YA HA SIDO REGISTRADO')]"
                  required
                  counter="10"
                  placeholder="NÚMERO DE TELÉFONOs *"
                  type="number"
                  class="my-1"
                  outlined
                  rounded
                  dark
                  @change="verificarTel()"
                  @keyup="maxlengthTel()"
                >
                </v-text-field>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      outlined
                      dark
                      height="45px"
                      rounded
                      label="FECHA DE NACIMIENTO"
                      v-model="fecha"
                      :rules="[(v) => !!v || 'INGRESA UNA FECHA DE NACIMIENTO']"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <div class="grey--text mt-n1" style="font-size: 10pt">
                          FECHA DE NACIMIENTO *
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fecha"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - 568036800000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    locale="mx-es"
                    @change="save"
                    mode="rgba"
                    color="rgba(0, 255, 157, 1)"
                    header-color="rgba(0, 255, 157, 1)"
                  ></v-date-picker>
                </v-menu>
                <v-select
                  :items="items"
                  dense
                  outlined
                  dark
                  required
                  :rules="[(v) => !!v || 'SELECCIONA UN GÉNERO']"
                  placeholder="GÉNERO *"
                  class="my-n1"
                  rounded
                  v-model="genero"
                >
                </v-select>
                <v-select
                  :items="estados"
                  dense
                  outlined
                  dark
                  v-model="estadoR"
                  :rules="[(v) => !!v || 'SELECCIONA UN ESTADO']"
                  label="ESTADO"
                  required
                  class="my-n1"
                  rounded
                  @change="dato()"
                >
                  <template v-slot:label>
                    <div class="grey--text mt-n1" style="font-size: 10pt">
                      ESTADO *
                    </div>
                  </template>
                </v-select>
                <v-text-field
                  v-model="codigoP"
                  height="45px"
                  :rules="[(v) => !!v || 'INGRESA CÓDIGO POSTAL', (v) => v.length == 5 || 'INGRESA CÓDIGO POSTAL']"
                  required
                  counter="5"
                  placeholder="CÓDIGO POSTAL *"
                  type="number"
                  class="my-0"
                  outlined
                  maxlength="5"
                  @keyup="maxlengthCP()"
                  rounded
                  dark
                >
                </v-text-field>
                <v-checkbox
                  dark
                  v-model="check"
                  label="DESEO RECIBIR INFORMACIÓN COMERCIAL Y EVENTOS DE GRUPO MODELO"
                ></v-checkbox>
                <v-radio-group
                  v-model="checkbox"
                  :rules="[(v) => !!v || 'DEBE ACEPTAR TÉRMINOS Y CONDICIONES Y AVISO DE PRIVACIDAD.']"
                  required
                  dark
                  class="my-n10"
                >
                  <v-radio value="1" dark>
                    <template v-slot:label>
                      <div class="grey--text mt-10" style="font-size: 10pt">
                        ACEPTO 
                        <a class="color_becks" href="#" @click="c('legal')">
                          <u>TÉRMINOS Y CONDICIONES.</u>
                        </a>
                        Y 
                        <a class="becks--text" href="https://www.grupomodelo.com/abilegal/politica-deproteccion-de-datos-personales" @click="clickPrivacidad()">
                          <u>AVISO DE PRIVACIDAD</u>
                        </a>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <a class="becks--text d-none" href="https://www.grupomodelo.com/abilegal/politica-deproteccion-de-datos-personales" id="enlacePrivacidad"  ref="enlacePrivacidad" target="_blank">
                  texto invisible
                </a>
                <aviso id="aviso" class="d-none" />
                <legal id="legal" class="d-none" />
                <v-btn color="becks" block class="mt-15" @click="validate">
                  &uacute;nete
                </v-btn>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import menus from "../components/menus.vue";
import axios from "axios";
import Legal from "../components/legal.vue";
import Aviso from "../components/aviso.vue";

export default {
  data: () => ({
    correoTemporal: false,
    correoVerificado: true,
    telefonoVerificado: true,
    check: false,
    items: ["HOMBRE", "MUJER", "PREFIERO NO DECIR"],
    emailRules: [
      (v) => !!v || "E-MAIL ES REQUERIDO",
      (v) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || "EL E-MAIL NO ES VALIDO",
    ],
    estados: [
      "AGUASCALIENTES",
      "BAJA CALIFORNIA",
      "BAJA CALIFORNIA SUR",
      "CAMPECHE",
      "CHIAPAS",
      "CHIHUAHUA",
      "COAHUILA",
      "COLIMA",
      "CDMX",
      "DURANGO",
      "GUANAJUATO",
      "GUERRERO",
      "HIDALGO",
      "JALISCO",
      "ESTADO DE MEXICO",
      "MICHOACAN",
      "MORELOS",
      "NAYARIT",
      "NUEVO LEON",
      "OAXACA",
      "PUEBLA",
      "QUERETARO",
      "QUINTANA ROO",
      "SAN LUIS POTOSI",
      "SINALOA",
      "SONORA",
      "TABASCO",
      "TAMAULIPAS",
      "TLAXCALA",
      "VERACRUZ",
      "YUCATAN",
      "ZACATECAS",
    ],
    estadoR: "",
    genero: "",
    telefono: "",
    codigoP: "",
    valid: false,
    valid2: false,
    nombre: "",
    apellido: "",
    email: "",
    provider_id: "",
    fecha: null,
    activePicker: null,
    checkbox: 0,
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    verificarMail(){
      var vm = this
      axios.post("validar_correo.php",{
        mail: this.email
      }).then(function (response) {
        if(response.data.message != "mail sin registrar"){
          if(response.data.message == "correo temporal"){
            vm.correoTemporal = true
          }else{
            vm.correoTemporal = false
          }
          vm.correoVerificado = false
        }else {
          vm.correoVerificado = true
        }
      })
    },
    verificarTel(){
      var vm = this
      axios.post("validar_numero.php",{
        phone: this.telefono
      }).then(function (response) {
        if(response.data.message == "numero registrado"){
          vm.telefonoVerificado = false
        }else {
          vm.telefonoVerificado = true
        }
      })
    },
    clickPrivacidad(){
      this.$refs.enlacePrivacidad.click();
    },
    sinNumeros(str){
      return /^[a-z\sñÑáéíóúÁÉÍÓÚ]+$/i.test(str) ? str = str: str = str.substring(0,str.length-1)
    },
    maxlengthTel(){
      this.telefono.length > 10 ? this.telefono = this.telefono.substring(0, 10) : this.telefono = this.telefono
    },
    maxlengthCP(){
      this.codigoP.length > 5 ? this.codigoP = this.codigoP.substring(0, 5) : this.codigoP = this.codigoP
    },
    c(id) {
      document.getElementById(id).firstElementChild.click();
    },
    dato() {
      // console.log(this.estadoR)
    },
    cambiarP(lugar) {
      // console.log("");
      axios
        .get("/")
        .then((response) => this.$router.push({ name: lugar }))
        .catch((error) => console.log("Hubo un error: " + error));
    },
    save(fecha) {
      this.$refs.menu.save(fecha);
    },
    // Funcion que realiza el envio de datos a php para realizar el registro
    async validate() {
      
      this.$refs.form.validate();
      var vm = this
      if (
        this.valid &&
        this.nombre != "" &&
        this.apellido != "" &&
        this.email != "" &&
        this.fecha != "" &&
        this.checkbox
      ) {
        axios
          .post("/update.php", {
            name: this.nombre,
            apellido: this.apellido,
            mail: this.email,
            phone: this.telefono,
            date_b: this.fecha,
            gender: this.genero,
            estado: this.estadoR,
            code_p: this.codigoP.padStart(5,"0"),
            newsletter: this.check, //Esto solo es un true o false
          })
          .then( async function (response) {

            if (response.data.message == "ya existe") {
                vm.$router.push({name: 'IniciarSesion'})
            }else {
              const utf8 = new TextEncoder().encode(vm.email);
              const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
              const hashArray = Array.from(new Uint8Array(hashBuffer));
              const hashHex = hashArray
                .map((bytes) => bytes.toString(16).padStart(2, '0'))
                .join('');
              
              eventoBecks('Registro',hashHex)
              
              vm.$router.push({name: 'Manifiesto'})
            }

          })
          .catch((error) => {
            
          });

        // this.$router.push({ name: "Manifiesto" });
      }
    }
  },
  computed: {},
  created() {
    // this.nombre = sessionStorage.name;
    // this.email = sessionStorage.email;
    // this.provider_id = sessionStorage.provider_id;
    // this.checkCookie();
  },
  name: "IniciarSesions",
  components: {
    menus,
    Legal,
    Aviso,
  },
};
</script>
<style>
.algo {
  border-radius: 50%;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: block !important;
  align-items: center !important;
  z-index: 3;
}
.v-text-field .v-input__control .v-input__slot ::placeholder {
  font-size: 15px;
}
.v-label{
  font-size: 10pt;
}
.v-picker__title {
  color: #000 !important;
}
.largo {
  max-width: 500;
}
.textoR {
  font-size: 30pt;
  font-weight: bold;
}
a:link {
  color: rgba(0, 255, 157, 1) !important;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: rgba(0, 255, 157, 1) !important;
  background-color: transparent;
  text-decoration: underline;
}
@media (max-width: 960px) {
  .textoR {
    font-size: 20pt;
    font-weight: bold;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>