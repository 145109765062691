<!--Componente con el modal de los términos y condiciones -->
<template>
<div>
    <span @click="modal= !modal"> TÉRMINOS Y CONDICIONES </span>
    <v-dialog v-model="modal" width="90%" >
        <v-card color="rgba(0,0,0,.81)" rounded="xl" class="white--text" >
            <v-responsive
                aspect-ratio="1"
                class="textoR"
            >
                <v-card-title class="ml-md-4 mr-md-4">
                    <v-spacer/>
                    <v-btn
                        icon
                        color="white"
                        class="mr-n3 mb-n2 close_ticket"
                        @click="modal = !modal"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-title class="ml-md-4 mr-md-4">
                    <v-spacer />
                        Aceptación de los Términos y Condiciones
                    <v-spacer />
                </v-card-title>
                <v-spacer>

                </v-spacer>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Para ingresar y hacer uso de la plataforma perteneciente al sitio de Internet de “BECKS” (unlockbecks.com), 
                    y sus redes sociales (en lo sucesivo los “Medios”), se deberá dar lectura íntegra a los siguientes términos 
                    y condiciones (los “Términos y Condiciones”), y cumplir totalmente con los requisitos y obligaciones aquí 
                    establecidos. Al ingresar, registrar, visitar, navegar, utilizar los Medios y/o cualquier interacción que se 
                    pueda darse con los Medios, el Usuario acepta estar obligado por los Términos y Condiciones así como sujetarse a 
                    las Políticas o Aviso de Privacidad.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Si no está de acuerdo con estos Términos y Condiciones, no deberá utilizar los Medios, estos Términos de Uso son un contrato que rige la relación entre usted y el Proveedor.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">Definiciones</v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Además de los términos en su caso definidos en los párrafos que preceden, para efectos de estos Términos y Condiciones se entenderá por:
                    <ul class="mx-5">
                        <li>Sitio WEB.- La dirección electrónica (unlockbecks.com), a través de la cual se podrá hacer uso del Servicio, sujetándose los Usuarios a los presentes Términos y Condiciones y al Aviso de Privacidad.</li>
                        <li>Usuario.- A la persona física mayor de edad y que cuente con capacidad de ejercicio, conforme a las leyes de la República Mexicana, y que cumpla con los requisitos aquí establecidos, ingrese a los Medios.</li>
                        <li>Proveedor.- Cervecería Modelo de México, S. de R.L. de C.V., el cual tiene su domicilio en Cerrada Palomas 22, Piso 4, Col. Reforma Social, Del. Miguel Hidalgo, C.P. 11650, Ciudad de México.</li>
                        <li>Territorio: La República Mexicana, limitándose a las áreas de cobertura de servicio. Para mayor información consulta el Sitio WEB.</li>
                    </ul>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    En caso de haber más definiciones en el cuerpo de los presentes Términos y Condiciones se señalará enseguida su significado.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Los términos referidos en este apartado tendrán igual significado ya sea que se utilicen en singular o plural.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Modificaciones a los Términos y Condiciones
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Proveedor se reserva el derecho de cambiar ocasionalmente estos Términos y Condiciones de acuerdo a las necesidades del Proveedor y los Medios, con o sin notificación a los Usuarios y será responsabilidad de estos últimos de revisar la versión más reciente de estos Términos y Condiciones y estará disponible en el Sitio WEB, misma que reemplazará las versiones anteriores. Con el ingreso y  uso de los Medios una vez que se hayan realizado las modificaciones a estos Términos y Condiciones significa que se aceptan dichos cambios.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Privacidad
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">Para más información, consulta nuestra política de privacidad.</v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">Del Uso del Sitio Web </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Usuario se compromete, además de lo establecido en los presentes, a: (a) utilizar el Sitio WEB tal como fue indicado, únicamente para uso personal y no comercial; (b) no copiar, ni distribuir ninguna parte del Sitio WEB sin la autorización previa y por escrito del Proveedor; y (c) no alterar ni modificar ninguna parte del Sitio WEB y (d) no compartir el Sitio WEB o su contenido con personas menores de edad.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Para poder tener acceso a los Medios, el Usuario deberá declarar su mayoría de edad, no pudiendo utilizar el Sitio WEB persona menor de edad. 
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Proveedor puede proporcionar enlaces a sitios WEB pertenecientes o gestionados por terceros, sin que por este hecho pueda entenderse, bajo ninguna circunstancia, que el Proveedor respalda el contenido, productos o servicios disponibles en dichos sitios web, y que no es responsable de su contenido o su seguridad. El enlace o conexión del Usuario a cualquier otro sitio web es de su exclusiva responsabilidad.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">Requisitos para los Usuarios</v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El público en general, podrá hacer uso de los Medios, cuando reúna todos y cada uno de los siguientes requisitos:
                    <ul>
                        <li>Ser mayor de edad conforme a las leyes de la República Mexicana, cuyo supuesto general es de 18 años.</li>
                        <li>Contar con una identificación oficial vigente que acredite la fecha de su nacimiento (credencial para votar, pasaporte, cartilla del servicio militar, cédula profesional, etc., expedida por una institución gubernamental oficial).</li>
                        <li>Dar lectura total y aceptar los presentes Términos y Condiciones. Se entenderán aceptados los presentes Términos y Condiciones por toda persona que ingrese a los Medios.</li>
                    </ul>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">Dinámica</v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    La dinámica  "BECKS" es un servicio que da la oportunidad  a los Usuarios a través de los Medios con la finalidad de subir una foto o video, tomada en ese momento o de su biblioteca personal de fotos y video y la personalicen con los activos predeterminados que vivirán en la página web(en lo sucesivo “los Materiales”) , el Proveedor no se hace responsable por las imágenes o fotografías que desee cargar el usuario al Sitio WEB. El proveedor podrá utilizar en sus campañas publicitarias los Materiales compartidos por los Usuarios, el Usuario acepta y reconoce que no recibirá algún tipo de compensación económica, gratificación o regalías por el uso de los Materiales.    
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Proveedor se reserva el derecho de eliminar automáticamente los comentarios, imágenes, fotografías o videos que usen palabras o imágenes altisonantes, violentas, ofensivas, que vayan en contra de la moral y las buenas costumbres, que muestren personas consumiendo o manipulando cerveza, que hagan alusión a la manipulación o consumo de cerveza, que relacionen directa o indirectamente o hagan alusión a menores de 18 años. Los Usuarios NO podrán relacionar sus fotografías, videos o imágenes con el consumo de bebidas alcohólicas.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">El Proveedor es el encargado de cuidar los lineamientos establecidos en Ley y demás regulaciones aplicables para la legal operación de los Medios.</v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">Propiedad intelectual</v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Usuario y el Proveedor aceptan y reconocen que los contenidos del Sitio WEB incluyendo de manera enunciativa más no limitativa, textos, marcas, avisos comerciales, denominaciones, fotografías, diseños, logotipos, imágenes, sonidos, videos, animaciones, grabaciones, programas de cómputo, así como otros signos distintivos y, en general, cualquier creación intelectual disponible en los Medios, están debidamente registrados y protegidos por las leyes de Propiedad Intelectual e Industrial aplicables al efecto. En virtud de lo anterior se le prohíbe al Usuario a cualquier persona que acceda a los Medios, su uso sin haber obtenido la licencia o autorización que corresponda conforme a la ley de la materia.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Autorización de Uso de Datos, Obra e Imagen
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    De así requerirse, los Usuarios se obligan a prestar su imagen y/o fotografía que suba al Sitio WEB para la elaboración de material fotográfico o publicitario, así como grabaciones de audio y video a utilizarse en los medios de comunicación que el Proveedor juzgue convenientes. Al participar los Usuarios autorizan al Proveedor a publicar las imágenes o fotografías que suba al sitio WEB a través de cualquier medio mundial o local de comunicación que estime conveniente. Así mismo, se comprometen a firmar cualquier documento que el Proveedor estime pertinente para hacer uso de su obra, voz y/o imagen a través de cualquier medio de comunicación conocido o por conocerse.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Los materiales a los que se hace referencia en el párrafo anterior, el Proveedor podrán difundir y utilizar de la manera que más convenga a sus intereses en cualquier medio de comunicación, sin que esto implique pago alguno al Usuario por su imagen y/o fotografía que suba al Sitio WEB.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Otros
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Se entenderá que toda persona que, directa o indirectamente, solicite o reciba el Servicio, ha conocido, entendido y aceptado íntegramente sujetarse y regirse conforme a los presentes Términos y Condiciones, renunciando expresamente a su derecho a deducir, promover, reclamar o ejercer cualquier acción en contra del Proveedor relacionada con los supuestos en que su responsabilidad está limitada o excluida en virtud de estos Términos y Condiciones hasta el máximo permitido por la ley.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Este Servicio está sujeto a cambios ocasionados por virtud de las leyes federales, locales o de resolución por parte de autoridades administrativas o judiciales, sin previo aviso a los Usuarios o al público en general.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Usuario conoce y acepta que cualquier contenido generado por éste y relacionado con el Sitio WEB, que sea obsceno, difamatorio, esté en contra de las disposiciones legales aplicables, constituya una conducta criminal u ofensiva y que promueva el consumo indebido y excesivo del alcohol, será eliminado en automático.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Uso de "cookies"
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Sitio WEB podrá utilizar archivos temporales (“cookies”) para facilitar el acceso al Sitio WEB o la Aplicación. Asimismo el navegador o dispositivo que se utilice podrá generar cookies que permitan la operación de otras. Puede desactivarlas y/o eliminarlas mediante las opciones de privacidad de su navegador de Internet; en el entendido que ello podría afectar el uso del Sitio WEB o la Aplicación.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Causales de exclusión de responsabilidad
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Cada Usuario es el único y absoluto responsable de todas las actividades que realice al ingresar al Sitio WEB.<br>
                    El Proveedor no otorga garantía, ni asume responsabilidad alguna por ningún daño y/o perjuicio directo o indirecto relacionado con la precisión e integridad del contenido del Sitio WEB o de cualquier sitio enlazado al del Proveedor, en virtud de lo anterior el Proveedor no es responsable por (a) errores o imprecisiones en el contenido del Sitio WEB, (b) lesiones personales o daños a la propiedad de cualquier tipo que resulten del acceso o uso del Sitio WEB, (c) acceso no autorizado a los Servidores del Proveedor y a la información de cualquier tipo almacenada en los mismos, (d) interrupción o inhabilitación temporal o permanente del Sitio WEB, (e) errores en el software utilizado, virus, troyanos, gusanos, malware o similares, que hayan sido introducidos en el Sitio WEB por cualquier tercero; y (f) errores u omisiones en el contenido o por cualquier pérdida o daño de cualquier tipo que resulte del uso de cualquier contenido publicado, enviado por correo electrónico, transmitido o de alguna otra forma puesto a disposición del Usuario mediante el sitio WEB.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Asimismo el Usuario acepta que el Proveedor no se hace responsable por ningún daño y/o perjuicio directo o indirecto proveniente de o en relación con (i) el rendimiento o navegación en los Medios; o (ii) sus enlaces a otros sitios web, incluso si el Usuario ha sido informado de la posibilidad de tales daños. El Usuario acepta que el Proveedor no se hace responsable de los daños y/o perjuicios derivados de la interrupción, suspensión o terminación de los Servicios cuando sea aplicable conforme a los presentes Términos y Condiciones o la legislación vigente aplicable.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El Usuario reconoce y acepta que, dadas las características del flujo de información en Internet, resulta materialmente imposible para el Proveedor controlar y garantizar la ausencia de virus u otros elementos similares que puedan producir alteraciones en su sistema informático o en los documentos electrónicos almacenados en su sistema informático.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Jurisdicción aplicable
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Cualquier controversia suscitada por los Medios, el Sitio WEB y sus complementarias, se regirán por los presentes Términos y Condiciones, y por las leyes vigentes en los Estados Unidos Mexicanos, sujetándose a la jurisdicción y competencia de los Tribunales de la Ciudad de México, renunciando a la competencia de los tribunales que por beneficio de su domicilio presente o futuro pudiese corresponderles a las partes.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4 text-right">
                    Fecha de última actualización 09 de noviembre de 2020.
                </v-card-subtitle>
            </v-responsive>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
export default {
  name: "aviso",
  data: () => ({
    modal: false,
  }),
};
</script>
<style scoped>
.close_ticket{
            display: flex;
            right: 10px;
        }
.textoR{
    font-size: 30pt;
}
@media (max-width: 960px){
    .textoR{
        font-size: 13pt;
    } 
}
</style>
