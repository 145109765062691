<!--Componente con el modal del aviso de privacidad -->
<template>
<div>
    <span @click="modal= !modal"> AVISO DE PRIVACIDAD </span>
    <v-dialog v-model="modal" width="90%" >
        <v-card color="rgba(0,0,0,.81)" rounded="xl" class="white--text" >
            <v-responsive
                aspect-ratio="1"
                class="textoR"
            >
                <v-card-title class="ml-md-4 mr-md-4">
                    <v-spacer/>
                    <v-btn
                        icon
                        color="white"
                        class="mr-n3 mb-n2 close_ticket"
                        @click="modal = !modal"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-title class="ml-md-4 mr-md-4">
                    <v-spacer />
                        Aviso de Privacidad
                    <v-spacer />
                </v-card-title>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(I) “Responsable” del tratamiento de sus datos personales.</b> Cervecería Modelo de México, S. de R.L. de C.V., sus afiliadas, subsidiarias, empresa matriz y/o partes relacionadas (en adelante “Grupo Modelo” o el “Responsable”), es la persona moral, que de acuerdo con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, (“Ley”) se entiende como el “Responsable”, ya que decide sobre el tratamiento de los datos personales que recaba de usted, como “Titular” de los mismos, entendiéndose como Titular la persona física a quien corresponden los datos personales, y por éstos últimos cualquier información concerniente a una persona física identificada o identificable.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El “Responsable” tratará sus datos de manera legítima y de conformidad con la ley aplicable. Este Aviso de Privacidad complementa cualesquiera otros avisos de privacidad simplificados o cortos que el “Responsable” haya puesto a su disposición por ser el titular de sus datos personales y resulta supletorio en todo aquello que expresamente no refieran tales avisos.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(II) Domicilio del “Responsable”.</b> Para efectos de oír y recibir todo tipo de notificaciones relacionadas con el presente Aviso de Privacidad, el “Responsable” designa aquel ubicado en la calle Cerrada Palomas # 22, piso 6, colonia Reforma Social, alcaldía Miguel Hidalgo, C.P. 11650, Ciudad de México, México.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(III) Datos personales que recaba el “Responsable”.</b> Usted puede navegar en nuestro sitio web sin proporcionar datos personales, sin embargo, en caso que decida contactarnos, registrarse o inscribirse a cualquiera de nuestros programas, promociones, trivias, dinámicas, encuestas o entrar a ciertos apartados en los cuales usted puede voluntariamente proporcionar sus datos para las distintas finalidades aquí descritas. 
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Para registrarse o inscribirse a cualquiera de nuestros programas, promociones, trivias, encuestas y concursos se recabaran los siguientes datos personales: nombre, correo electrónico, teléfono, fecha de nacimiento, género, ciudad y código postal. 
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Adicionalmente, recabaremos la identificación oficial y datos de geolocalización del dispositivo electrónico a través del cual usted se registre y/o inscriba a nuestras promociones, trivias, programas y/o concursos.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(IV) Datos personales sensibles.</b> Grupo Modelo le informa que para cumplir con las finalidades previstas en este aviso de privacidad no requiere recabar de usted datos personales sensibles. 
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(V) Finalidades del tratamiento de los datos personales recabados.</b> El “Responsable” recabará sus datos personales para las siguientes finalidades:
                    <ul>
                        <li>Dar seguimiento y/o contestación a cualquier duda, comentario o sugerencia que usted realice a través de nuestra página web; </li>
                        <li>Crear un expediente para efectos internos para poder registrarlo, inscribirlo y darlo de alta en nuestros programas, trivias, dinámicas, encuestas, promociones y concursos;</li>
                        <li>Registrarlo, inscribirlo y darlo de alta en nuestros programas, trivias, dinámicas, encuestas, promociones y concursos; </li>
                        <li>Dar cumplimiento a las obligaciones contraídas con usted,  </li>
                        <li>Comunicarnos con usted para verificar su identidad y mayoría de edad en caso de que usted se desee registrar, inscribir y/o darse de alta en nuestros programas, trivias, dinámicas, encuestas, promociones y concursos; </li>
                        <li>Comunicar e informarle sobre cualquier cambio en los programa, trivias, dinámicas, encuestas, promociones y concursos en que usted se haya registrado o inscrito;</li>
                        <li>Datos de geolocalización y demográficos referentes a la ubicación geográfica del dispositivo que utilice para contactarnos y/o registrarse o inscribirse en nuestros programas, trivias, dinámicas, encuestas, promociones y concursos; </li>
                        <li>Realizar actividades de análisis de datos a través de la página web y/o aplicación móvil a través del uso de tecnologías de inteligencia artificial y análogas para análisis estadísticos e históricos; </li>
                        <li>Enviar, comunicar y/o gestionar la generación de códigos de seguridad, QR y de verificación para su participación en nuestros programas, trivias, dinámicas, encuestas, promociones y concursos; y</li>
                        <li>En su caso, contactarle para iniciar el proceso de registro o su permanencia a lo largo de su participación en cualquiera de nuestros programas, trivias, dinámicas, encuestas, promociones y concursos.</li>
                        <li>Utilizar su imagen para las finalidades establecidas en los Términos & Condiciones. </li>
                        <li>Detectar la filtración de contenido delicado o sensible.</li>
                        <li>Identificar a los participantes que incumplan con los Términos & Condiciones.  </li>
                    </ul>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    De manera adicional, utilizaremos su información personal para las siguientes finalidades que no son necesarias para mantener una relación con usted, pero que nos permiten y facilitan brindarle una mejor atención:
                    <ul>
                        <li>Evaluar la calidad de los servicios profesionales, programas, trivias, dinámicas, encuestas, promociones y concursos que presta el “Responsable” y llevar a cabo encuestas de satisfacción de clientes; </li>
                        <li>Prospección comercial personalizada mediante el ofrecimiento de nuestros productos y servicios, así como cualquier oferta o promoción referente a éstos; </li>
                        <li>Enviarle publicidad sobre ofertas y promociones de nuestros productos y/o servicios; </li>
                        <li>Estudios de carácter estadístico, tales como encuestas o estudios sobre hábitos de consumo y de mercado; y</li>
                        <li>Enviarle correos informativos e invitaciones a eventos, programas, trivias, dinámicas, encuestas, promociones y concursos de su interés de conformidad con los servicios prestados por el “Responsable”.</li>
                    </ul>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    En caso que desee oponerse al tratamiento de sus datos personales para las finalidades secundarias (no necesarias), por favor envíe un correo a <a href="mailto:datos.legal@ab-inbev.com">datos.legal@ab-inbev.com</a> indicando su oposición al tratamiento de sus datos personales para los fines secundarios:
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(VI) Opciones y medios para limitar el uso o divulgación de sus datos personales.</b> Para limitar el uso o divulgación de sus datos personales, a fin de que no sean tratados para fines mercadotécnicos o publicitarios por parte del Grupo Modelo, usted puede contactar directamente al Departamento de Datos Personales, ubicado en el domicilio de Grupo Modelo, o a través del siguiente correo electrónico <a href="mailto:datos.legal@ab-inbev.com">datos.legal@ab-inbev.com</a>, a fin de que se le indiquen la forma y los medios por los que usted pueda limitar el uso y divulgación de sus datos personales.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>VII) Medios para ejercer los Derechos de Acceso, Rectificación, Cancelación y Oposición (Derechos ARCO).</b> Usted tiene el derecho de acceder a sus datos personales en posesión de Grupo Modelo, así como a los detalles y características del tratamiento de los mismos, a rectificarlos en caso de ser inexactos o incompletos; y a cancelarlos cuando considere que no se requieren para alguna de las finalidades antes señaladas, o en caso que usted considere que estén siendo utilizados para finalidades no consentidas; o bien oponerse al tratamiento de los mismos.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    El trámite para el ejercicio de los derechos ARCO iniciará con la presentación de una solicitud en el domicilio de Grupo Modelo, dirigido al Área Legal: Departamento de Datos Personales o bien a través del siguiente correo electrónico <a href="mailto:datos.legal@ab-inbev.com">datos.legal@ab-inbev.com</a>, dicha solicitud deberá contener por lo menos la siguiente información: 
                    <ol type="i">
                        <li>nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud; </li>
                        <li>Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
                        <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos ARCO; y</li>
                        <li>Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
                    </ol>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Previo a iniciar el ejercicio de sus derechos ARCO, así como para información adicional le sugerimos contactar al Departamento de Datos Personales, (tal como se indica en el punto anterior) en donde le brindarán toda la información y formatos necesarios para un eficiente ejercicio de dichos derechos.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(VIII) Procedimiento para que el titular, en su caso, revoque su consentimiento para el tratamiento de sus datos personales.</b> En cualquier momento, usted puede revocar el consentimiento que, en su caso, haya otorgado a Grupo Modelo para el tratamiento de sus datos personales. Sin embargo, no en todos los casos se podrá atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal, Grupo Modelo deba o requiera continuar con el tratamiento de sus datos personales. Asimismo, usted debe tener en cuenta que tratándose de ciertas finalidades específicas, la revocación de su consentimiento implicará la terminación de su relación con el Grupo Modelo.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    En caso de querer revocar su consentimiento será necesario que contacte al Departamento de Datos Personales, ubicado en el domicilio de Grupo Modelo, o a través de la siguiente dirección de correo electrónico <a href="mailto:datos.legal@ab-inbev.com">datos.legal@ab-inbev.com</a>, a fin de que le puedan brindar la información y/o formatos necesarios  para iniciar un eficiente procedimiento para la revocación del consentimiento.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(IX) Transferencias de datos personales dentro de México y al extranjero.</b> Grupo Modelo no realizará transferencia alguna de sus datos personales a otros terceros. Sin embargo, el Grupo Modelo podrá llevar a cabo transferencias de sus datos sin su consentimiento en los supuestos previstos por la Ley.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Asimismo, nuestra página web podría contener hipervínculos (links), banners y/o botones que pueden redirigir a los usuarios a otros portales o sitios de Internet propiedad de u operados por terceros y otros servicios que pueden estar compartidos con terceros. Los datos personales que los usuarios proporcionen en dichos portales o sitios de Internet no se encuentran amparados bajo este aviso de privacidad, por lo tanto su tratamiento no es responsabilidad de Grupo Modelo. Cualquier información proporcionada por los usuarios en sitios o portales de terceros se sujetará a los respectivos avisos de privacidad que se contengan en cada uno de ellos.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(X) RECOLECCIÓN DE DATOS PERSONALES A TRAVÉS DE COOKIES Y WEB BEACONS.</b> Grupo Modelo recaba datos personales directamente del titular o a través de otros medios ópticos, sonoros, visuales, gráficos, o por cualquier otra tecnología legalmente permitida.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    La información que Grupo Modelo recibe y almacena de las páginas de internet y/o aplicaciones móviles cuando usted interactúa en los sitios web y/o aplicaciones de Grupo Modelo es principalmente información técnica, como su dirección de protocolo de internet, su sistema operativo en su dispositivo y su tipo de navegador, la dirección de un sitio web de referencia, en su caso, y la ruta que siga durante su recorrido por la página web y/o aplicación.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Cuando visita nuestras páginas web y/o aplicaciones, los “datos de uso” temporales se guardan como un registro en nuestro servidor con fines estadísticos para mejorar la calidad de nuestros sitios web. Este conjunto de datos comprende:
                    <ul>
                        <li>la página web y/o aplicación mediante la cual los datos fueron requeridos;</li>
                        <li>el nombre de los datos;</li>
                        <li>la fecha y hora de la solicitud;</li>
                        <li>el volumen de datos transferidos;</li>
                        <li>el estado de acceso (archivo transferido, archivo no encontrado);</li>
                        <li>la descripción del tipo de navegador web utilizado; y</li>
                        <li>la dirección IP del dispositivo solicitante, acortada a los últimos tres caracteres.</li>
                    </ul>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Grupo Modelo usa esta información para permitir el acceso a nuestra página web y/o aplicación, para el control y la administración de nuestros sistemas, y para mejorar el diseño de las páginas web y/o aplicaciones. La información guardada es anonimizada (disociada).
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    “No rastrear” es una configuración de navegador opcional que permite a los usuarios optar por dejar de rastrear los sitios web que visitan, incluidos servicios de análisis, redes publicitarias y plataformas sociales. Puede ajustar la configuración en su navegador y optar por “No rastrear”. Una vez que haya hecho esto, se enviará una solicitud de “No rastrear” cuando acceda a un sitio web desde su navegador, y su actividad de usuario no será rastreada
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Grupo Modelo utiliza cookies para reconocerlo cuando utilice o regrese a sus sitios web. Las cookies permiten a Grupo Modelo reconocerlo de página en página. Las cookies son pequeños archivos de texto que los sitios web que usted visita colocan en su dispositivo. Son ampliamente utilizados para hacer que los sitios web funcionen o funcionen de manera más eficiente, así como para proporcionar información a los propietarios del sitio web. Esta página web y/o aplicación utiliza cookies de sesión para permitirle iniciar sesión en su cuenta de usuario personalizada. Estas cookies de sesión no permanecen en la página web y/o aplicación o en su dispositivo después de cerrar la página web y/o aplicación. Esta página web y/o aplicación también usa Google® Analytics®. Si no desea que se utilicen las cookies, no utilice nuestro sitio web, ya que no se pueden desactivar.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-center">
                                Tipo Cookie
                            </th>
                            <th class="text-center">
                                Nombre
                            </th>
                            <th class="text-center">
                                Objetivo
                            </th>
                            <th class="text-center">
                                <b>Informacion Adicional</b>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Temporal</td>
                                <td>bud_auth</td>
                                <td>Autenticación del usuario -- sin esta cookie, el usuario no podría iniciar sesión.</td>
                                <td><b>Expira al finalizar la sesión.</b></td>
                            </tr>
                            <tr>
                                <td>Temporal</td>
                                <td>bud_session</td>
                                <td>Estado de sesión del usuario conectado -- sin esta cookie el usuario tendría que iniciar sesión para ver cada página.</td>
                                <td><b>Expira al finalizar la sesión.</b></td>
                            </tr>
                            <tr>
                                <td>Temporal & Permanente</td>
                                <td>__utma __utmb __utmc __utmz</td>
                                <td>Google® Analytics®y Salesforce Krux utilizan cookies para ayudarnos a analizar cómo usa esta página web y/o aplicación. Usamos la información para compilar informes y ayudarnos a mejorar. Las cookies recopilan información de forma anónima, incluida la cantidad de visitantes, desde donde los visitantes han accedido a la página web y/o aplicación que visitaron. Para obtener una descripción general de privacidad de Google, <a href="https://privacy.google.com/?hl=es" target="_blank"> haga clic aquí</a></td>
                                <td><b>_utma expira 2 años después de su creación __utmb expira 30 minutos después del último acceso a la página web_utmc expira 30 minutos después del último acceso a la página web _utmz expira 6 meses después del último acceso a la página web</b></td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    La mayoría de los navegadores web permiten cierto control de la mayoría de las cookies a través de la configuración del navegador. Para obtener más información sobre las cookies, incluida la forma de ver qué cookies se han establecido y cómo administrarlas y eliminarlas, visite <a href="http://www.allaboutcookies.org" target="_blank">http://www.allaboutcookies.org</a>.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Es importante mencionar que si elige deshabilitar por completo el uso de cookies en su navegador, es posible que esta página web y/o aplicación no se muestre o no funcione como se diseñó en su dispositivo.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Esta página web y/o aplicación utiliza herramientas de análisis web que utilizan cookies y dispositivos similares para permitirnos analizar el uso de esta página web y/o aplicación. Para que podamos optimizar nuestra página web y/o aplicación, la información generada en la cookie (incluida la dirección IP abreviada) se transmite a nuestro servidor y se almacena. Durante este proceso, su dirección de IP será inmediatamente anonimizada (disociada), para que usted permanezca en el anonimato. La información generada por la cookie sobre su uso de este sitio web no será divulgada a terceros.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Realizamos anuncios adaptados a sus intereses mediante la cooperación con redes publicitarias, proveedores de anuncios publicitarios y servicios similares. Podemos recopilar información sobre su actividad en línea a través de tecnologías de seguimiento tales como cookies, píxeles de seguimiento y dispositivos similares, para realizar anuncios y servicios en línea que sean relevantes para usted. Puede obtener más información acerca de la publicidad dirigida y tomar ciertas decisiones de privacidad aquí.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(XI) Medidas de seguridad implementadas.</b> Para la protección de sus datos personales hemos instrumentado medidas de seguridad de carácter administrativo, físico y técnico con el objeto de evitar pérdidas, mal uso y/o alteración de su información.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(XII) Modificaciones al aviso de privacidad.</b> El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de las propias necesidades de Grupo Modelo por los servicios que se ofrecen, de las prácticas de privacidad o diversas causas, por lo que Grupo Modelo se reserva el derecho de efectuar en cualquier momento las modificaciones o actualizaciones que sean necesarias al presente aviso de privacidad.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    Grupo Modelo pondrá a su disposición, la versión actualizada del aviso de privacidad, en el sitio web y/o la aplicación de Grupo Modelo.
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4">
                    <b>(XIII) Derecho de promover los procedimientos de protección de derechos y de verificación que sustancia el Instituto.</b> Cualquier queja o información adicional respecto al tratamiento de sus datos personales o duda en relación con la Ley, podrá dirigirla al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).
                </v-card-subtitle>
                <v-card-subtitle class="ml-md-4 mr-md-4 text-right">
                    <b>Fecha de última actualización: noviembre de 2021.</b>
                </v-card-subtitle>
            </v-responsive>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
export default {
  name: "aviso",
  data: () => ({
    modal: false,
  }),
};
</script>
<style scoped>
.close_ticket{
            display: flex;
            right: 10px;
        }
.textoR{
    font-size: 30pt;
}
@media (max-width: 960px){
    .textoR{
        font-size: 13pt;
    } 
}
</style>
